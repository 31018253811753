<template>
  <div id="QuestionIndex">
    <!-- 查询 -->
    <div class="search-input-div">
      <van-search class="search-input" v-model="query.examPaperName" show-action placeholder="请输入关键词查询" @search="toQuery">
        <template #action>
          <div @click="toQuery">搜索</div>
        </template>
      </van-search>
    </div>
    <!-- 卡片 -->
    <van-list v-model="loading" :immediate-check="false" offset="30" :finished="finished" finished-text="没有更多了" @load="onLoad" style="margin-bottom:64px">
      <van-empty v-if="showEmpty" description="暂无数据" />
      <van-cell-group v-else inset style="margin-top:15px" v-for="(row,index) in data" :key="index">
        <van-cell>
          <template #title>
            <span class="custom-title hidden-text">{{row.examPaperName}}</span>
          </template>
          <template #label>
            <div class="custom-centent margin6 hidden-text95">
              <van-icon name="clock-o" /> 截至日期: {{parseTime(row.invTask.startTime,"{y}-{m}-{d}") }} -{{ parseTime(row.invTask.endTime,"{y}-{m}-{d}")  }}
            </div>
            <div class="custom-centent margin6 hidden-text95">
              <van-icon name="apps-o" /> 状态: {{ row.status=== 0?'待完成':"已完成"  }}
            </div>
          </template>
          <template #right-icon>

          </template>
        </van-cell>
        <van-cell id="opt-style">
          <template #title>
            <van-button class="table-edit-btn" plain @click="toReport(row)" :disabled="!isDuringDate(row.invTask.startTime,row.invTask.endTime) || row.status=== 1" round size="small" type="primary">填报</van-button>
          </template>
          <template #right-icon>
            <span class="table-detail-btn " @click="toDetail(row)" style="color:#B2B2B2"> 详情
              <van-icon name="arrow" />
            </span>
          </template>
        </van-cell>
      </van-cell-group>
    </van-list>
  </div>
</template>

<script>

import initData from "@/mixins/initData";
import { parseTime, isDuringDate } from '@/utils'


export default {
  name: "QuestionIndex",
  mixins: [initData],
  data() {
    return {}
  },
  methods: {
    isDuringDate,
    beforeInit() {
      this.url = "/api/invTaskStu/stu";
      this.sort = ['id,desc',
        'unitNo,asc',
        'specCode,asc',
        'classNo,asc',
        'stuName,asc',];
      return true;
    },
    toDetail(data) {
      this.$router.push({
        path: "/xs/question/detail",
        query: {
          titleText: '调查问卷详情(' + data.stuName + ')',
          examinationId: data.examinationId,
          taskId: data.taskId,
          zbid: data.id,
        },
      });
    },
    toReport(data) {
      this.$router.push({
        path: "/xs/question/form",
        query: {
          titleText: '调查问卷填报',
          id: data.examinationId,
          stuNo: data.stuNo,
          taskId: data.taskId,
          zbid: data.id,
        },
      });
    },
  }
}
</script>

<style lang="scss" scoped>
#QuestionIndex {
}
</style>